// import loadjs from "load-js";
import { loadScript } from './utils/loadScript';
import { IConfig } from './utils/IConfig';

// apprach inspired by https://stackoverflow.com/a/56216283
const awaitPayengineObject = async () => {
  // console.log("waiting for variable");
  while (
    !window.hasOwnProperty('PayEngine') // define the condition as you like
  )
    await new Promise(resolve => setTimeout(resolve, 100));
  // console.log("window.PayEngine is defined");

  // TODO: Implement eventual timeout if object does not get loaded
};

const loadPayengine = async (config: IConfig) => {
  console.log('starting loadPayengine');

  if (!config && window.PayEngine) {
    return window.PayEngine;
  }

  if (!config) {
    throw new Error(
      'Must specify configuration paramenter for Payengine library on first run'
    );
  }

  if (!window.PayEngine) {
    let {
      publicKey = null,
      version = '1.0.0',
      scriptURL = null,
      loglevel = null,
      lightweight = false,
    } = config;

    if (publicKey == null) {
      throw new Error('Payengine public key missing');
    }

    const loglevelStr = loglevel !== null ? `&loglevel=${loglevel}` : ``;

    if (scriptURL == null) {
      const baseURL =
        publicKey.indexOf('_prod_') > 0
          ? 'https://console.payengine.co'
          : 'https://console.payengine.dev';
      scriptURL = `${baseURL}/js/${version}/${
        lightweight ? 'securefields.min.js' : 'embed.js'
      }`;
    } else if (lightweight) {
      console.warn(
        'By specifying custom scriptURL lightweight parameter will be ignored'
      );
    }

    scriptURL = `${scriptURL}?key=${publicKey}${loglevelStr}`;

    try {
      await loadScript(scriptURL);
      await awaitPayengineObject();
    } catch (error) {
      throw error;
    }
  }

  return window.PayEngine;
};

const payengineStyles = (styles: Record<string, string>) => {
  return JSON.stringify(styles);
};

export { loadPayengine, payengineStyles };
